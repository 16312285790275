import { HStack, Heading, Text, VStack } from '@chakra-ui/react';
import YouTube from 'react-youtube';
import React from 'react';
import { useBreakpointValue } from "@chakra-ui/react";

function About() {
  const videoWidth = useBreakpointValue({ base: "96%", lg: "100%" });
  return (
    <VStack w="100%" spacing={'20'}>
      <Heading>O que fazemos</Heading>
      <HStack
        flexDirection={{ sm: 'column', lg: 'row' }}
        spacing={['20', '5']}
        w="100%"
        alignItems="start"
      >
        <YouTube
          videoId="iQf1LhWDUkw"
          opts={{
            width: videoWidth,
            height: '330',
          }}
          style={{
            width: videoWidth,
          }}
        />
        <Text textAlign={'justify'} w={['100%', '50%']} lineHeight="base">
          Em maio de 2013, nasce o Sindicato da Indústria da Construção Pesada
          do Estado do Ceará (Sinconpe/CE). Essa organização surgiu através da
          necessidade das empresas locais de construção pesada em ter uma
          representação em decorrência das demandas. O Sinconpe/CE vem atuando
          ativamente em defesa dos anseios da classe, buscando melhorias de
          tabelas de preços e na qualidade das obras. Nossa missão é representar
          e defender os interesses do nosso segmento, com o desenvolvimento do
          setor da construção pesada. Para fins conceituais, consideram-se
          atividades da construção pesada, o conjunto de atividades integrantes
          da Categoria Econômica identificada de acordo com o Plano Básico de
          Enquadramento Sindical a que se reporta o art. 577 da Consolidação das
          Leis de Trabalho, no 30 Grupo do Plano da Confederação Nacional da
          Indústria , correspondendo – segundo a Norma de Classificação
          NBR-8950, de julho de 198 , da ABNT.
        </Text>
      </HStack>
    </VStack>

  );
}

export default About;
