import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  chakra,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  Button,
} from '@chakra-ui/react';
import Pdf from '../../../assets/pdfs/edital.pdf';
import Edital2 from '../../../assets/edital2.jpeg';
import News2 from '../../../assets/news/2.png';
import News3 from '../../../assets/news/3.png';
import News4 from '../../../assets/news/4.jpeg';
import edital3 from '../../../assets/edital3.jpeg';

const BlogTags = (props) => {
  const { marginTop = 0, tags } = props;

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="twitter" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const News = () => {
  return (
    <Container maxW={'7xl'} p="5 12">
      <Heading as="h1" w="100%" textAlign="center">
        Notícias
      </Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display={{ base: 'flex', lg: 'flex', sm: 'block', md: 'block' }}
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%"
          >
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={Edital2}
                alt="img"
                cursor="pointer"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Box
            zIndex="1"
            width="100%"
            position="absolute"
            height="100%"
            flexWrap="wrap"
            gridGap={6}
            justify="center"
          >
            <Box
              bgGradient={useColorModeValue(
                'radial(blue.600 1px, transparent 1px)',
                'radial(blue.300 1px, transparent 1px)'
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <BlogTags tags={['Construção Pesada']} />
          <Heading marginTop="1">
            <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
              EDITAL DE CONVOCAÇÃO - ASSEMBLEIA GERAL EXTRAORDINÁRIA
            </Text>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign="justify"
          >
            O Presidente do Sindicato da Indústria da Construção Pesada do Ceará
            - SINCONPE/CE, entidade sindical de 1° grau, no uso das atribuições
            que lhe se conferidas pelo Estatuto Social, convoca as empresas,
            filiadas e associadas, pertencentes à categoria econômica da
            Indústria da Construção Pesada do Ceará, para comparecerem à
            Assembleia Geral Extraordinária, que se realizará na Rua Tomas
            Acioli, 840 - 3° andar, sala 304 - Aldeota- Fortaleza- Ceará (CEP:
            60.135-180), no dia 05 de fevereiro de 2024, às 08:30h.
          </Text>
          <br></br>
          <Text>🗓 05/02</Text>
          <Text>🕗 08h30 </Text>
          <Text>
            📍 Rua Tomas Acioli, 840 - 3° andar, sala 304 - Aldeota- Fortaleza-
            Ceará{' '}
          </Text>
          <br></br>

          <BlogAuthor name="ENGAJA" date={new Date('2024-01-26T19:01:27Z')} />
        </Box>
      </Box>
      <Heading as="h2" marginTop="5">
        Últimas Notícias
      </Heading>
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: '100%', sm: '95%', md: '45%', lg: '30%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={edital3}
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Box>
            </Box>
            <BlogTags tags={['Construção Pesada']} marginTop={3} />
            <Heading fontSize="xl" marginTop="2">
              <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
                EDITAL DE CONVOCAÇÃO - ASSEMBLEIA GERAL EXTRAORDINÁRIA
              </Text>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              O presidente do SINCONPE/CE convoca as empresas, filiadas e
              associadas, para uma Assembleia Geral Ordinária a ser realizada no
              dia 05 de fevereiro de 2024, às 10:00h, em primeira convocação e,
              não havendo quórum, às 10:30h, em segunda convocação, com qualquer
              número de presentes A ordem do dia inclui a apreciação e votação
              orçamentária de 2024 e assuntos correlatos. As empresas podem ser
              representadas por diretores ou procuradores com poderes
              específicos, munidos de procuração. O local da assembleia é na Rua
              Tomás Acioli, 840 - 3º andar, sala 304 - Aldeota- Fortaleza- Ceará
              (CEP: 60.135-180).
            </Text>
            <BlogAuthor name="Engaja" date={new Date('2024-01-26T19:01:27Z')} />
          </Box>
        </WrapItem>
        <WrapItem width={{ base: '100%', sm: '95%', md: '45%', lg: '30%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={News3}
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Box>
            </Box>
            <BlogTags tags={['Engenharia', 'Dica do SINCONPE']} marginTop={3} />
            <Heading fontSize="xl" marginTop="2">
              <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
                Modais de transportes: novos caminhos
              </Text>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2" textAlign="justify">
              Com o tema central “Modais de Transporte: Novos Caminhos”,
              avaliará as oportunidades e os desafios para expansão dos modais
              rodoviário, ferroviário e aeroportuário, bem como traçará um
              panorama da infraestrutura no país e o papel das concessões e do
              investimento privado para o desenvolvim...
            </Text>
            <BlogAuthor name="ENGAJA" date={new Date('2023-08-23T19:01:27Z')} />
          </Box>
        </WrapItem>
        <WrapItem width={{ base: '100%', sm: '95%', md: '45%', lg: '30%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={News4}
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Box>
            </Box>
            <BlogTags
              tags={['Engenharia', 'Construção Pesada']}
              marginTop={3}
            />
            <Heading fontSize="xl" marginTop="2">
              <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
                Ricardo Cavalcante participa da solenidade de retomada do Pacto
                pelo Pecém
              </Text>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              O Presidente da Federação das Indústrias do Estado do Ceará
              (FIEC), Ricardo Cavalcante, participou, juntamente com o
              Governador do Estado do Ceará, Elmano de Freitas, da cerimônia de
              retomada do Pacto pelo Pecém, nesta manhã de terça-feira (22/08).
              O programa é uma colaboração entre diversos atores ligados ao
              Complexo Industrial e Portuário do Pecém, incluindo o Governo do
              Ceará, CIPP S/A, Assembleia Legislativa do Ceará...
            </Text>
            <BlogAuthor name="ENGAJA" date={new Date('2022-08-23T19:01:27Z')} />
          </Box>
        </WrapItem>
      </Wrap>
      <VStack w="100%" spacing={'10'} pt="5%">
        <Button mb="5%" size="lg" colorScheme="twitter">
          Veja Mais
        </Button>
      </VStack>
    </Container>
  );
};

export default News;
