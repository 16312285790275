import { Heading, Modal, ModalOverlay, chakra, ModalContent, ModalCloseButton, ModalBody, Center, Button, Stack, Flex, ButtonGroup, Input, HStack, FormLabel, FormControl, useColorModeValue, Box, useDisclosure, Tr, Td, Avatar, Image } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../Theme/Inputs/TextField';


function EditarServicos(prop) {
    const [numero, setNumero] = useState([]);
    const modal = prop.disclosure;
    const nome = prop.nome;
    const descricao = prop.descricao
    const [servicos, setServicos] = useState([]);
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/getServicosById`;

    useEffect(() => {
        if (prop.id_descricao.length > 0) {
            const fetchPosts = async () => {
                const vals = {
                    id_servicos: prop.id_descricao,
                }
                const response = await fetch(url, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(vals),
                });
                const postsData = await response.json();
                setServicos(postsData);
            }

            fetchPosts();
        }
    }, [prop.id_descricao]);


    function updateForm(dados) {
        let formData = new FormData()
        const imageData = document.getElementById('fotografia')
        formData.append('image', imageData.files[0])
        formData.append('nome', dados.nome)
        formData.append('descricao', dados.descricao)
        formData.append('id_descricao', prop.id_descricao)
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/updateServicos/`, {
            method: 'POST',
            body: formData,
        }).catch(function (err) {
            console.log(err)
        }).then(async (response) => {
            return response.json()
        }).then(async (value) => {
            console.log(value)
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/image/updateImage/`, {
                method: 'POST',
                body: formData,
            }).catch(function (err) {
                console.log(err)
            }).then(async (response) => {
                return response.json()
            }).then(async (value) => {
                modal.onClose()
                return value
            })
        })

    }
    const imageUrl = `${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/servicephoto${prop.id_descricao}.webp`;

    return (
        <>
            <Modal isOpen={modal.isOpen} isCentered onClose={modal.onClose}>
                <ModalOverlay />
                <Formik
                    initialValues={{ nome: nome, descricao: descricao }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={false}
                    onSubmit={(values, actions) => {
                        updateForm(values)
                    }}

                >
                    <ModalContent maxW={{ md: "30%", lg: "30%", sm: "90%" }} h="35em" borderRadius="0.5em">
                        <ModalCloseButton />
                        <ModalBody>
                            <Center>
                                <Heading textAlign="center" color="#808080" mt="5" size="md">
                                    Editando dados de um serviço
                                </Heading>
                            </Center>

                            <Form >
                                <Box mt={4}>
                                    <Box>
                                        <FormLabel>Nome</FormLabel>
                                        <TextField type="text" name="nome" placeholder="Digite seu nome" />
                                    </Box>

                                    <Box mt={4}>
                                        <FormLabel>Descrição</FormLabel>
                                        <TextField type="text" name="descricao" placeholder="Digite seu email" />
                                    </Box>

                                    <Box mt={4}>
                                        <Box
                                            w="50%"
                                            h="10em"
                                            bgSize="cover"
                                            bgPosition="center"
                                            bgRepeat="no-repeat"
                                            boxShadow="lg"
                                            borderRadius="md"
                                            ml={'5em'}
                                        >
                                            <chakra.img src={`${imageUrl}`} alt="calor" crossOrigin="anonymous" />
                                        </Box>
                                        <FormLabel>Foto</FormLabel>
                                        <Input name="fotografia" id="fotografia" type="file" />
                                    </Box>

                                    <ButtonGroup>
                                        <Button type="submit" mt={4} colorScheme="green">
                                            Cadastrar
                                        </Button>
                                        <Button mt={4} colorScheme="red" onClick={() => { modal.onClose() }}>
                                            Fechar
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </Form>


                        </ModalBody>
                    </ModalContent>
                </Formik>
            </Modal>
        </>
    )

}


export default EditarServicos