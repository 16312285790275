import { Button, Center, Heading, Modal, ModalBody, useToast, chakra, ModalCloseButton, Stack, ModalContent, ModalOverlay, Input, useColorModeValue, Box, ButtonGroup, useDisclosure, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../../Theme/Inputs/TextField';
import ModalValidacao from '../../Auth/Modal/ModalValidacao'
function ModalEsqueciSenha(prop) {
    const modal = prop.disclosure;
    const toast = useToast();
    const [validacao, SetValidacao] = useState(false);
    const [client_email, SetEmail] = useState('');
    const ModalValidacaoDisclousure = useDisclosure();

    function callToast(color, title, description, status) {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: color,
            containerStyle: {
                paddingTop: '50px',
            },
        })
        return toast
    }
    let email = ''
    function sendCode(email, id) {
        let codigo = []
        for (let i = 0; i < 6; i++) {
            const randomInteger = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
            codigo.push(randomInteger);
        }
        const codigoSemVirgula = codigo.join(' ');
        const vals = {
            to_message: email,
            code: codigoSemVirgula,
            id_client: id,
        };
        try {
            SetValidacao(true)
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/email/resetPasswordEmail`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(vals),
            }).then((response) => {
                console.log(response)
                if (!response || !response.ok || response.status >= 400) {
                    callToast('red', 'Indisponível', 'Não foi possível enviar um e-mail de contato no momento. Tente novamente', 'warning')
                    return;
                }
                callToast('green', 'Sucesso!', 'E-mail de redefinição de senha enviado. Obrigado por entrar em contato conosco.', 'success')
                return response.json();
            })
        } catch (error) {
            console.log(error)
        }
    }

    function resetPassword(values) {
        try {
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/getClientByEmail/${values.email}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).catch(function (err) {
                console.log(err)
            }).then(async (response) => {
                return response.json()
            }).then(async (resposta) => {
                console.log(resposta)
                if (resposta.sucess === false) {
                    callToast('red', 'Indisponível', 'Não foi possível encontrar seu e-mail em nosso banco de dados. Tente novamente', 'warning')
                } else {
                    SetEmail(values.email)
                    sendCode(values.email, resposta.data.id_client)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Modal isOpen={modal.isOpen} isCentered onClose={modal.onClose}>
                <ModalOverlay />
                <ModalContent maxW={{ md: "30%", lg: "30%", sm: "90%" }} h="20em" borderRadius="0.5em">
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs >
                            <TabList>
                                <Tab>Envio do código</Tab>
                                <Tab display={validacao === true ? 'block' : 'none'} color="green" fontWeight={'bold'}>Validação do código</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Center>
                                        <Heading textAlign="center" color="#808080" mt="5" size="md">
                                            Informe seu e-mail abaixo que para receber um código de recuperação
                                        </Heading>
                                    </Center>
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validationSchema={Yup.object({
                                            email: Yup.string().required('Email obrigatório!').trim().matches('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'Email inválido'),
                                        })}
                                        onSubmit={(values, actions) => {
                                            resetPassword(values)
                                            actions.resetForm();
                                        }}
                                    >
                                        <Form>
                                            <Box mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
                                                <TextField
                                                    name="email"
                                                    type="email"
                                                    color={useColorModeValue('gray.800', 'gray.200')}
                                                    bg={useColorModeValue('gray.100', 'gray.600')}
                                                    rounded={'full'}
                                                    border={0}
                                                    _focus={{ bg: useColorModeValue('gray.200', 'gray.800'), outline: 'none', }}
                                                />
                                                <Button
                                                    bg={'blue.400'}
                                                    rounded={'full'}
                                                    color={'white'}
                                                    flex={'1 0 auto'}
                                                    borderRadius={'0.8em'}
                                                    mt="2em"
                                                    _hover={{ bg: 'blue.500' }}
                                                    _focus={{ bg: 'blue.500' }}
                                                    type='submit'

                                                >
                                                    {validacao === true ? 'Reenviar Código' : 'Enviar código'}
                                                </Button>
                                            </Box>
                                        </Form>
                                    </Formik>
                                </TabPanel>
                                <TabPanel>
                                    <ModalValidacao email={client_email} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

export default ModalEsqueciSenha