import { Button, HStack, Heading, Text, VStack, Flex } from '@chakra-ui/react';
import React from 'react';
import ProfileCard from '../../Theme/Cards/ProfileCard';
import { useNavigate } from 'react-router-dom';
function Directors() {
  const navigate = useNavigate();
  return (
    <VStack w="100%" spacing={'10'} pt="5%">
      <Heading>Diretoria</Heading>
      <HStack spacing={'25'}>
        <Flex flexWrap="wrap" gridGap={9} justify="center">
          <ProfileCard name={'Dinalvo Carlos Diniz'} position={'Presidente'} company={'Beta Engenharia SA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
          <ProfileCard name={'Ananias Pinheiro'} position={'Vice-presidente'} company={'Engexata Engenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
          <ProfileCard name={'Eduardo Benevides'} position={'Vice-presidente'} company={'Copa Egenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
        </Flex>
      </HStack>
      <Button mb="5%" size="lg" colorScheme="twitter" onClick={() => { navigate('/lista_diretoria'); }}>
        Veja Mais
      </Button>
    </VStack>
  );
}

export default Directors;
