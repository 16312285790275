import React from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

function Users() {
  return (
    <>
      <VStack w="100%" h={900}>
        <Text fontWeight="bold" w="97%" mt="1%">
          Usuarios
        </Text>

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow="hidden"
          variant="outline"
          w="98%"
          h="70%"
          borderRadius="15px"
          boxShadow="md"
          bg={useColorModeValue('gray.100', 'gray.900')}
        >
          <VStack w="100%"></VStack>
        </Card>
      </VStack>
    </>
  );
}

export default Users;
