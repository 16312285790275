import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NewPost from '../../../Administration/Pages/Shared/NewPost';

import { AccountContext } from '../../../AccountContext';

function Sidebar() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { setUserLocation, userLocation } = useContext(AccountContext);
  const navigate = useNavigate();

  const newPostDisclosure = useDisclosure();
  const [activeOption, setactiveOption] = useState(null);
  const handleItemClick = (id) => {
    setactiveOption(id);
  };
  return (
    <>
      <NewPost disclosure={newPostDisclosure} />
      <Flex
        width="100%"
        height="100%"
        bg={useColorModeValue('#333', 'gray.900')}
      >
        <VStack w="100%" mt={5} textColor="white">
          <VStack>
            <Image
              src={
                'https://sinconpece.com.br/wp-content/uploads/2016/01/logo-branco.png'
              }
              w="60%"
              m="10%"
            />
          </VStack>
          <VStack w="100%" textAlign="center" height="15%">
            <HStack width="90%">
              <InputGroup>
                <Input
                  name="searchBar"
                  placeholder="buscar"
                  focusBorderColor="teal"
                  backgroundColor="white"
                  shadow="lg"
                  textColor="black"
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      console.log('searching');
                    }
                  }}
                />
                <InputLeftElement width="15%">
                  <Button
                    variant="ghost"
                    colorScheme="red"
                    width="100%"
                    children={<Search2Icon color="black" />}
                    onClick={() => {
                      console.log('click');
                    }}
                    isLoading={loading}
                  ></Button>{' '}
                </InputLeftElement>
              </InputGroup>
            </HStack>
            <Button
              w="90%"
              colorScheme="red"
              variant="solid"
              onClick={() => {
                newPostDisclosure.onOpen();
              }}
            >
              Novo Post
            </Button>
          </VStack>
          <Accordion w="100%" allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" fontWeight="bold" textAlign="left">
                  Administração
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} width="100%">
                <Box
                  w="100%"
                  height="5%"
                  color={activeOption === 'users' ? 'red.500' : 'white'}
                  mb="2%"
                  id="menu-opt"
                  cursor="pointer"
                  onClick={() => {
                    handleItemClick('users');
                    setUserLocation('/administration/users');
                  }}
                >
                  Usuários
                </Box>
                <Box
                  w="100%"
                  height="5%"
                  cursor="pointer"
                  id="menu-opt"
                  color={activeOption === 'permissions' ? 'red.500' : 'white'}
                  mb="2%"
                  onClick={() => {
                    handleItemClick('permissions');
                    setUserLocation('/administration/permissions');
                  }}
                >
                  Permissões
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Box
            w="100%"
            height="5%"
            id="menu-opt"
            onClick={() => {
              console.log('click');
            }}
          >
            <chakra.p
              height="100%"
              lineHeight="100%"
              verticalAlign="middle"
              margin="5%"
              fontWeight="bold"
            >
              Relatorios
            </chakra.p>
          </Box>
          <Accordion w="100%" allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" fontWeight="bold" textAlign="left">
                  Páginas
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} width="100%">
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Inicio
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Sinconpece
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Associados
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Diretoria
                </Box>
                <Box
                  w="100%"
                  height="5%"
                  color={activeOption === 'servicos' ? 'red.500' : 'white'}
                  cursor="pointer"
                  mb="2%"
                  id="menu-opt"
                  onClick={() => {
                    handleItemClick('servicos');
                    setUserLocation('/administration/servicos');
                  }}
                >
                  Serviços
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Notícias
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Agenda
                </Box>
                <Box w="100%" height="5%" id="menu-opt" mb="2%">
                  Contato
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box w="100%" height="5%" id="menu-opt">
            <chakra.p
              height="100%"
              lineHeight="100%"
              verticalAlign="middle"
              margin="5%"
              fontWeight="bold"
            >
              Mensagens
            </chakra.p>
          </Box>
        </VStack>
      </Flex>
    </>
  );
}

export default Sidebar;
