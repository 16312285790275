import { extendTheme } from "@chakra-ui/react";
  const theme = {
 
  breakpoints:{
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",

  },fonts: {
    heading: `'inter', sans-serif`,
    body: `'inter', sans-serif`,
  },
};

export default extendTheme(theme);