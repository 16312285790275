import React, { useState, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  Button,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import TextField from '../../../Theme/Inputs/TextField';

function NewPost({ disclosure }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState();
  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    /*let text = blocks.reduce((acc, item) => {
      acc = acc + item.text;
      return acc;
    }, "");*/
    let text = editorState.getCurrentContent().getPlainText('\u0001');
    setText(text);
  };

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Novo Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody W="100%">
          <>
            {/*<div>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div>*/}
            <Formik initialValues={{ title: '', type: '', description: '' }}>
              <Flex as={Form} direction="column" w="100%" mb="1%">
                <HStack mb="1%">
                  <TextField name="title" type="text" placeholder="Titulo" />
                  <Select
                    name="type"
                    placeholder="Selecione o tipo da postagem"
                    isRequired
                    label="djaisjd"
                    mt="1%"
                  >
                    <option value="noticia">Notícia</option>
                    <option value="agenda">Agenda</option>
                  </Select>
                </HStack>
                <VStack>
                  <Textarea
                    name="description"
                    place
                    holder="Pequena descrição sobre a noticia para o card de preview"
                  />

                  <Input type="file" />
                </VStack>
              </Flex>
            </Formik>
            <VStack w="100%">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                mention={{
                  separator: ' ',
                  trigger: '@',
                }}
              />
            </VStack>
            <div
              style={{
                visibility: 'hidden',
              }}
            >
              {text}
            </div>
          </>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={disclosure.onClose}>
            Fechar
          </Button>
          <Button colorScheme="linkedin">Salvar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewPost;
