import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Card,
    Heading,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import CadastroServicos from '../Modal/CadastroServicos';
import { AccountContext } from '../../AccountContext';
import ServicesList from '../Pages/ServicesList';

function Servicos() {
    const modal = useDisclosure();
    const { user } = useContext(AccountContext);
    const [refetch, setRefetch] = useState(false)
    const [servicos, setServicos] = useState([]);
    const [id_descricao, setIdDescricao] = useState();
    const [fetchAtualizado, setAtualizar] = useState(false);
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/getServicos`;
    const atualizarFetch = r => {
        setAtualizar(r)
        console.log(r)

        if (fetchAtualizado === true) {
            fetchPosts()
        }
    }

    const fetchPosts = async () => {
        setAtualizar(false)
        const response = await fetch(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
        });
        const postsData = await response.json();
        setServicos(postsData);
        return postsData
    };


    return (
        <>
            <CadastroServicos disclosure={modal} display={{ md: 'none', lg: 'none' }} refetch={refetch} handleResult={atualizarFetch} />
            <VStack w="100%" h={900}>
                <Heading fontWeight="bold" w="97%" mt="1%" fontSize={'2em'}>Meus Serviços</Heading>
                <VStack w='100%' align='end'>
                    <Button mr="2em" backgroundColor={'green.400'} color={'white'} onClick={() => { modal.onOpen() }}>Cadastrar novo serviço</Button>
                </VStack>

                <Card
                    direction={{ base: 'column', sm: 'row' }}
                    overflow="hidden"
                    variant="outline"
                    w="98%"
                    h="70%"
                    borderRadius="15px"
                    boxShadow="md"
                    bg={useColorModeValue('gray.100', 'gray.900')}
                >

                    <ServicesList handleResult={fetchPosts} fetchAtualizado={fetchAtualizado} />
                </Card>
            </VStack>
        </>
    )
}

export default Servicos