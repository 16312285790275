import React from 'react'
import PageModel from '../../../Pages/PageModel'
import { Heading, VStack, Text, Box, Flex } from '@chakra-ui/react'
import image from '../../../../images/unnamed.jpg'
function Main() {
    return (
        <>
            <PageModel>
                <VStack minHeight="60vh" position="relative" >
                    <Heading mt={'2em'}>Convênios</Heading>
                    <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }} textAlign={'left'}>
                        O SINCONPE/CE busca sempre as melhores condições de negócios para os seus associados. Confira abaixo os diferenciais e as vantagens de cada convênio:
                    </Text>
                    <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                        <Box p={5} shadow='md' borderWidth='1px' height={'15em'} w={'25em'}
                            mx={3} mt={3} bgSize="cover"
                            bgImage={'https://cdn.awsli.com.br/2532/2532693/logo/6a35da77c1.png'} onClick={() => { window.location.href = ('https://linktr.ee/militaodelivery') }}>
                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px'
                            mx={3} mt={3} height={'15em'} w={'25em'}
                            bgImage={'https://www.fecomercio-se.com.br/wp-content/uploads/2021/05/Marca_Sistema.png'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://www.fecomercio-ce.com.br/') }}>

                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px' mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://uniateneu.edu.br/wp-content/uploads/2021/02/logo-home.jpg'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://uniateneu.edu.br/') }}>

                        </Box>
                    </Flex>

                    <Flex justifyContent="center" alignItems="center" flexWrap="wrap" >
                        <Box p={5} shadow='md' borderWidth='1px' mx={3} mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://www.mullercamacho.com.br/wp-content/uploads/2015/10/embracon_Muller-Camacho-04.jpg'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://www.embracon.com.br/parceiro/lidia-lisboa') }}>

                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px' mx={3} mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://media.starlightcms.io/workspaces/salutis/institucional/original/liv-saude-logo-8v6cokaiqu.png'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://livsaude.com.br/') }}>

                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px' mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://aluno.invictus.uses.com.br/imagens/logomarca.png'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://api.whatsapp.com/send?phone=558586904204&text=Oi,%20vim%20pela%20SINCONPE%20-%20CE!%20Como%20fa%C3%A7o%20para%20me%20matricular%20e%20me%20tornar%20fluente%20em%20apenas%2018%20meses%3F%20%F0%9F%A6%81') }}>

                        </Box>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" flexWrap="wrap" >
                        <Box p={5} shadow='md' borderWidth='1px' mx={3} mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://static.wixstatic.com/media/6245d7_081834ce160a492da5feffa67076b924~mv2.png'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://catedral.haws.website/?ref=247yrmox62prm&product_id=1') }}>

                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px' mx={3} mt={3}
                            height={'15em'} w={'25em'} bgImage={'https://storage.googleapis.com/production-hostgator-brasil-v1-0-9/699/188699/Hfiu4LeE/15f9b0b470964430847cb0068f279e9b'} bgSize="cover"
                            onClick={() => { window.location.href = ('https://unidental.com.br/') }}>

                        </Box>
                        <Box p={5} shadow='md' borderWidth='1px' mt={3} height={'15em'} w={'25em'} bgImage={image} bgSize="cover" onClick={() => { window.location.href = ('https://somosafinity.com.br/') }}>>

                        </Box>
                    </Flex>


                </VStack>
            </PageModel >
        </>
    )
}

export default Main