import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Dashboard from './DashboardContent';

import Users from '../Pages/Administration/Users';
import { AccountContext } from '../../AccountContext';
import Permissions from '../Pages/Administration/Permissions/Main';
import Servicos from '../Pages/Servicos'
function RenderScreen() {
    const { userLocation, setUserLocation } = useContext(AccountContext);
    switch (userLocation) {
        default:
            return <Dashboard />;
        case '/':
            return <Dashboard />;
        case '/administration/servicos':
            return <Servicos />;
        case '/administration/users':
           return <Users />;
        case '/administration/permissions':
           return <Permissions />;
    }
}

function Navigation() {
    return (
        <Routes>
            <Route path="/" element={<RenderScreen />} />
        </Routes>
    );

}

export default Navigation;
