import React, { useEffect, useState } from 'react'
import Footer from '../Theme/layout/Footer'
import Header from '../Theme/layout/Header'
import { VStack, WrapItem, chakra } from '@chakra-ui/react';

function PageModel({ children }) {
    return (
        <>
            <Header isHeaderTransparent={false} />
            <VStack backgroundColor="gray.200" w="100%" minHeight="100vh" p="2em">
                <VStack minHeight="100vh" position="relative" w="100%" backgroundColor="white">
                    {children}
                </VStack></VStack>

            <Footer />
        </>
    )
}

export default PageModel