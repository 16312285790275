import React, { useEffect, useState } from 'react';
import Header from '../Theme/layout/Header';
import ShowCase from '../Theme/Sliders/ShowCase';
import About from './Sections/About';
import Container from '../Theme/layout/Container';
import { chakra, Text } from '@chakra-ui/react';
import Associates from './Sections/Associates';
import Directors from './Sections/Directors';
import News from './Sections/News';
import Schedule from './Sections/Schedule';
import Contact from './Sections/Contact';
import Footer from '../Theme/layout/Footer';
import Services from './Sections/Services';
function Main() {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 60;
      setIsHeaderTransparent(scrollY <= threshold);
    };

    window.addEventListener('scroll', handleScroll);
    console.log(isHeaderTransparent);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  console.log(isHeaderTransparent);
  return (
    <>
      <chakra.div style={{ position: 'fixed', width: '100%', zIndex: 999 }}>
        <Header isHeaderTransparent={isHeaderTransparent} />
      </chakra.div>
      <chakra.div id="showcase">
        <ShowCase />
      </chakra.div>
      <Container>
        <chakra.div id="about">
          <About />
        </chakra.div>
      </Container>
      <chakra.div id="associates">
        <Associates />
      </chakra.div>
      <chakra.div id="directors">
        <Directors />
      </chakra.div>
      <chakra.div id="services">
        <Services />
      </chakra.div>
      <chakra.div id="news">
        <News />
      </chakra.div>
      <chakra.div id="schedule">
        <Schedule />
      </chakra.div>
      <chakra.div id="contact">
        <Contact />
      </chakra.div>
      <Footer />
    </>
  );
}

export default Main;
