import { VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

const Container = ({ mt, spacing, children }) => (
  <VStack w="100%" p="5%">
    {children}
  </VStack>
);

export default Container;
