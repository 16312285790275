import { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountContext } from './AccountContext';
//import Opening from './Auth/Abertura';
//import Opening from './Auth/SistemaEmManutencao';
//import Login from './Auth/Login';
import LandingPage from './LandingPage/Main';
//import SucessoGenerico from './Alerts/Sucesso';
//import ErroGenerico from './Alerts/Error';
import PrivateRoutes from './PrivateRoutes';
import Login from './Administration/Auth/Login';
import Dashboard from './Administration/Dashboard/Main';
import BlankPage from '../components/Pages/PageModel';

import IndicePage from '../components/LandingPage/Sections/Indice/Main';
import ConvenioPage from '../components/LandingPage/Sections/Convenio/Main'
import FiscalPage from '../components/LandingPage/Sections/Fiscal/Main'
import ConvecaoPage from '../components/LandingPage/Sections/Diretoria/Main'

import ListaDiretoria from './LandingPage/Sections/Diretoria/ListaDiretoria';


function Views() {
  const { user } = useContext(AccountContext);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/indice" element={<IndicePage />} />
      <Route path="/convenio" element={<ConvenioPage />} />
      <Route path="/fiscal" element={<FiscalPage />} />
      <Route path="/convencao" element={<ConvecaoPage />} />

      <Route path="/lista_diretoria" element={<ListaDiretoria />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<LandingPage />} />
      </Route>
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}

export default Views;
