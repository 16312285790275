import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Flex,
  Heading,
  HStack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

function Main() {
  const [list, setList] = useState([]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL_API_SERVER}/admin/list_permissions`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      }
    )
      .catch((err) => {
        //showToast();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          //showToast();
          return;
        }
        return res.json();
      })
      .then((data) => {
        setList(data);
        console.log(data);
      });
  }, []);

  const useList = list?.map((permission) => {
    return (
      <Tr>
        <Td isNumeric>{permission.profile_type_id}</Td>
        <Td>{permission.description}</Td>
        <Td></Td>
      </Tr>
    );
  });

  return (
    <>
      <VStack w="100%" h={900}>
        <Text fontWeight="bold" w="97%" mt="1%">
          Permissões
        </Text>

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow="hidden"
          variant="outline"
          w="98%"
          h="70%"
          borderRadius="15px"
          boxShadow="md"
          bg={useColorModeValue('gray.100', 'gray.900')}
        >
          <VStack w="100%" p="2%">
            <VStack w="100%" alignItems="end">
              <ButtonGroup colorScheme="teal">
                <Button>Criar Perfil</Button>
              </ButtonGroup>
            </VStack>
            <VStack w="100%" mt="2%">
              <TableContainer w="100%">
                <Table size="lg" variant="striped" colorScheme="red">
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Nome</Th>
                      <Th>Descrição</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{list && useList}</Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Nome</Th>
                      <Th>Descrição</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </VStack>
          </VStack>
        </Card>
      </VStack>
    </>
  );
}

export default Main;
