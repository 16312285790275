import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
const { createContext, useState, useEffect } = require('react');

export const AccountContext = createContext();

const UserContext = ({ children }) => {
  const location = useLocation();

  const [user, setUser] = useState({
    loggedIn: null,
    username: null,
    name: null,
    profile_type: null,
  });
  const [userLocation, setUserLocation] = useState('/');
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((prevState) => {
            console.log(prevState);
            return { loggedIn: false };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((prevState) => {
            console.log(prevState);
            return { loggedIn: false };
          });
          return;
        }
        console.log('reset');
        setUser((prevState) => ({ ...data }));

        navigate('/');
      });
  }, []);
  return (
    <AccountContext.Provider
      value={{ user, setUser, userLocation, setUserLocation }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default UserContext;