import React from 'react'
import PageModel from '../../../Pages/PageModel'
import { Flex, HStack, Heading, VStack } from '@chakra-ui/react'
import ProfileCard from '../../../Theme/Cards/ProfileCard'

function ListaDiretoria() {

    return (
        <>
            <PageModel>
                <VStack minHeight="60vh" position="relative" >
                    <Heading mt={'2em'}>Diretoria</Heading>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Dinalvo Carlos Diniz'} position={'Presidente'} company={'Beta Engenharia SA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Ananias Pinheiro'} position={'Vice-presidente'} company={'Engexata Engenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Eduardo Benevides'} position={'Vice-presidente'} company={'Copa Egenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Paulo Luna'} position={'Diretor'} company={'Construtora Batista Cavalcante'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Sebastião Cordeiro'} position={'Diretor'} company={'Construtora S & V LTDA-EPP'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Antonio Mendes'} position={'Diretor'} company={'TGA Tecnologia SA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Mariana Furlani Landim'} position={'Diretora'} company={'R Furlani Engenharia'} url="https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?w=826&t=st=1693276356~exp=1693276956~hmac=6b50b7039bc0b93f85e114a117275ba4f0d0d5c6ddaf1e1bf9c506b9a56c1627" />
                            <ProfileCard name={'Janio Keilthon'} position={'Diretor'} company={'COSAMPA PROJETOS E CONSTRUÇÕES LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Antonio Mendes'} position={'Diretor'} company={'TGA Tecnologia SA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                    <Heading mt={'2em'}>Conselho Fiscal - Titulares</Heading>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Igo Proença'} position={''} company={'Construtoora Rodovalhos Alencar LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Alexandre Ribeiro'} position={''} company={'Locamon Locação e Construção LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Diego Aguiar Benevides'} position={''} company={'Copa Engenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Heryson Reis de Oliveira'} position={''} company={'Construtora Batista Cavalcante LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Paulo Sanford'} position={''} company={'Construtora Britânica LTDA '} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Roberto Furlani'} position={''} company={'Caldas e Furlani Engenharia LTDA '} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                    <Heading mt={'2em'}>Suplentes</Heading>
                    <HStack spacing={'25'}>
                        <Flex flexWrap="wrap" gridGap={9} justify="center">
                            <ProfileCard name={'Cristiano Peixoto Maia'} position={'1 Suplente'} company={'Construtora Samaria LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                            <ProfileCard name={'Francisco Caldas da Silveira Junior'} position={'2 Suplente'} company={'Caldas e Furlani Engenharia LTDA'} url="https://img.freepik.com/free-vector/illustration-customer-service-concept_53876-5883.jpg?w=826&t=st=1693276415~exp=1693277015~hmac=90c17f2754002c8d478366bf94d22061bb46392f3bee1893596ac7eedaae987d" />
                        </Flex>
                    </HStack>
                </VStack>

            </PageModel>
        </>
    )
}

export default ListaDiretoria