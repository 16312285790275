import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';

function ServiceCard({ src, route, title, index, color }) {
  const navigate = useNavigate();

  return (
    <>
      <Card
        key={index}
        bgImage={src}
        bgSize="cover"
        width={'22em'}
        height={'14em'}
        onClick={() => {
          navigate(route);
        }}
        cursor="pointer"
        position="relative"
      >
        <CardBody padding="0">
          <Flex direction="column" justify="flex-end" height="100%">
            <Box backgroundColor={color} p="4" height={'6em'}>
              <Text
                color="white"
                fontWeight={'bold'}
                fontSize={'1em'}
                fontStyle={'Inter'}
              >
                {title}
              </Text>
              <CardFooter position="absolute" bottom="-1.5em" left="0">
                <Button
                  backgroundColor="transparent"
                  color={'white'}
                  fontWeight={'bold'}
                  fontSize={'1em'}
                  alignSelf="flex-end"
                  _hover={{ textDecoration: 'none' }}
                  onClick={() => {
                    navigate(route);
                  }}
                >
                  Clique Aqui
                </Button>
              </CardFooter>
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
}

export default ServiceCard;
