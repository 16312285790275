import React from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

function DashboardContent() {
  return (
    <>
      <VStack w="100%" h={900}>
        <Text fontWeight="bold" w="97%" mt="1%">
          Meu Painel
        </Text>
        <Flex w="98%" h="30%" paddingBottom="2%">
          <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="outline"
            mt="1%"
            w="50%"
            h="100%"
            borderRadius="15px"
            boxShadow="md"
            bg={useColorModeValue('gray.100', 'gray.900')}
          >
            <Heading pl="2%" pt="2%" size="sm"></Heading>
          </Card>
          <Box w="1%"></Box>
          <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="outline"
            mt="1%"
            w="50%"
            h="100%"
            borderRadius="15px"
            boxShadow="md"
            bg={useColorModeValue('gray.100', 'gray.900')}
          ></Card>
        </Flex>

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow="hidden"
          variant="outline"
          w="98%"
          h="70%"
          borderRadius="15px"
          boxShadow="md"
          bg={useColorModeValue('gray.100', 'gray.900')}
        >
          <VStack w="100%"></VStack>
        </Card>
      </VStack>
    </>
  );
}

export default DashboardContent;
