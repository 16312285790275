import { Box, Flex } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import Header from '../../Theme/layout/administration/Header';

import Sidebar from '../../Theme/layout/administration/Sidebar';


import DashboardContent from './Navigation';
import Navigation from './Navigation';
import { AccountContext } from '../../AccountContext';

function Main() {
  const [module, setModule] = useState('');

  return (
    <>
      <Flex>
        <Box w="15%" zIndex="99" position="relative">
          <Sidebar />
        </Box>
        <Box w="85%" zIndex="1" position="relative">
          <Header />
          <Navigation />
        </Box>
      </Flex>
    </>
  );
}

export default Main;
