import React from 'react'
import PageModel from '../../../Pages/PageModel'
import { Heading, ListItem, OrderedList, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'

function Main() {
    return (
        <>
            <PageModel>
                <VStack minHeight="60vh" position="relative" padding={'5%'}>
                    <Heading >Contribuição Sindical</Heading>
                    <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }} textAlign={'justify'} >
                        A Diretoria da CNI aprovou em 21/09/15, a Tabela da Contribuição Sindical das empresas industriais para 2016. O prazo de recolhimento expira em 31/01/17, devendo os débitos em atraso ser recolhidos nos termos do art. 600 da CLT.

                        Para os empregadores industriais que venham a estabelecer-se após essa data, o recolhimento deve ocorrer na ocasião em que requeiram às repartições o registro ou a licença para o exercício da respectiva atividade, consoante determina o art. 587 da CLT.
                    </Text>
                    <Heading fontSize={'2em'}>Aspectos Legais - Atividades</Heading>
                    <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }} textAlign={'justify'}>
                        Quando a empresa realizar diversas atividades econômicas, sem que nenhuma delas seja preponderante, cada uma dessas atividades será incorporada à respectiva categoria econômica, sendo a contribuição sindical devida à entidade sindical representativa da mesma categoria, procedendo-se, em relação aos correspondentes estabelecimentos, na forma antes descrita. Entende-se por atividade preponderante a que caracterizar a unidade de produto, operação ou objetivo final, para cuja obtenção todas as demais atividades converjam, exclusivamente, em regime de conexão funcional, conforme art. 581 da Consolidação das Leis do Trabalho.

                        TÍTULO V – DA ORGANIZAÇÃO SINDICAL
                        CAPÍTULO III – DA CONTRIBUIÇÃO SINDICAL
                        SEÇÃO I – DA FIXAÇÃO E DO RECOLHIMENTO DA IMPOSTO SINDICAL

                        Art. 581. Para os fins do item III do artigo anterior, as empresas atribuirão parte do respectivo capital às suas sucursais, filiais ou agências, desde que localizadas fora da base territorial da entidade sindical representativa da atividade econômica do estabelecimento principal, na proporção das correspondentes operações econômicas, fazendo a devida a comunicação às Delegacias Regionais do Trabalho, conforme localidade da sede da empresa, sucursais, filiais ou agências. (Redação dada pela Lei nº 6.386, de 9.12.1976)
                        …
                        § 2º Entende-se por atividade preponderante a que caracterizar a unidade de produto, operação ou objetivo final, para cuja obtenção todas as demais atividades converjam, exclusivamente em regime de conexão funcional. (Redação dada pela Lei nº 6.386, de 9.12.1976).
                    </Text>
                    <Heading fontSize={'2em'}>Tabela - 2017</Heading>
                    <TableContainer>
                        <Table size='sm' >
                            <Thead>
                                <Tr>
                                    <Th>Linha</Th>
                                    <Th>Classe de Capital Social (R$)</Th>
                                    <Th isNumeric>Aliquota</Th>
                                    <Th isNumeric>Valor a Adiconar (R$)</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>1</Td>
                                    <Td><b>De</b> 0,01 <b>a</b> 15.424,07	</Td>
                                    <Td isNumeric>-</Td>
                                    <Td isNumeric>123,39</Td>
                                </Tr>
                                <Tr>
                                    <Td>2</Td>
                                    <Td><b>De</b> 15.424,08 <b>a</b> 30.848,14</Td>
                                    <Td isNumeric>0,80</Td>
                                    <Td isNumeric>0,00</Td>
                                </Tr>
                                <Tr>
                                    <Td>3</Td>
                                    <Td><b>De</b> 30.848,15 <b>a</b> 308.481,42</Td>
                                    <Td isNumeric>0,20</Td>
                                    <Td isNumeric>184,09</Td>
                                </Tr>
                                <Tr>
                                    <Td>4</Td>
                                    <Td><b>De</b> 308.481,43 <b>a</b> 30.848.142,02</Td>
                                    <Td isNumeric>0,10</Td>
                                    <Td isNumeric>493,57</Td>
                                </Tr>
                                <Tr>
                                    <Td>5</Td>
                                    <Td><b>De</b> 30.848.142,03 <b>a</b> 164.523.424,09</Td>
                                    <Td isNumeric>0,02</Td>
                                    <Td isNumeric>25.172,08</Td>
                                </Tr>
                                <Tr>
                                    <Td>6</Td>
                                    <Td ><b>De</b> 164.523.424,10 <b>a</b> Em diante	</Td>
                                    <Td isNumeric>-</Td>
                                    <Td isNumeric>58.076,77</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Heading fontSize={'2em'}>Modo de Calcular - Exemplo</Heading>
                    <OrderedList>
                        <ListItem>Enquadre o capital social na “classe de capital” correspondente;</ListItem>
                        <ListItem>Multiplique o capital social pela aliquota correspondente à linha onde for enquadrado o capital;</ListItem>
                        <ListItem>Adicione ao resultado encontrado o valor constante na coluna “valor a adicionar”, relativo à linha da classe de capital;</ListItem>
                    </OrderedList>

                    <TableContainer>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Capital Social (R$)</Th>
                                    <Th>Classe de Capital Social (R$)</Th>
                                    <Th isNumeric>Aliquota</Th>
                                    <Th isNumeric>Resultado (R$)</Th>
                                    <Th isNumeric>Valor a adicionar (R$)</Th>
                                    <Th isNumeric>Combinação Devida (R$)</Th>
                                </Tr>
                            </Thead>
                            <Thead>
                                <Tr>
                                    <Th>(A)</Th>
                                    <Th>(B)</Th>
                                    <Th >(C)</Th>
                                    <Th >(A * C)</Th>
                                    <Th >(D)</Th>
                                    <Th >(A * C) + (D)</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>7.000,00</Td>
                                    <Td >Linha 1</Td>
                                    <Td isNumeric>-</Td>
                                    <Td isNumeric>0,00</Td>
                                    <Td isNumeric>123,39</Td>
                                    <Td isNumeric>123,39</Td>
                                </Tr>
                            </Tbody>
                            <Tbody>
                                <Tr>
                                    <Td>21.000,00</Td>
                                    <Td >Linha 2</Td>
                                    <Td isNumeric>0,80</Td>
                                    <Td isNumeric>184,00</Td>
                                    <Td isNumeric>0,00</Td>
                                    <Td isNumeric>184,00</Td>
                                </Tr>
                            </Tbody>
                            <Tbody>
                                <Tr>
                                    <Td>154.000,00</Td>
                                    <Td >Linha 3</Td>
                                    <Td isNumeric>0,20</Td>
                                    <Td isNumeric>338,00</Td>
                                    <Td isNumeric>185,09</Td>
                                    <Td isNumeric>523,09</Td>
                                </Tr>
                            </Tbody>
                            <Tbody>
                                <Tr>
                                    <Td>14.000.000,00</Td>
                                    <Td >Linha 4</Td>
                                    <Td isNumeric>0,10</Td>
                                    <Td isNumeric>15.000,00</Td>
                                    <Td isNumeric>493,57</Td>
                                    <Td isNumeric>15.493,57</Td>
                                </Tr>
                            </Tbody>
                            <Tbody>
                                <Tr>
                                    <Td>89.000.000,00</Td>
                                    <Td >Linha 5</Td>
                                    <Td isNumeric>0,02</Td>
                                    <Td isNumeric>19.400,00</Td>
                                    <Td isNumeric>25.172,08</Td>
                                    <Td isNumeric>44.572,08</Td>
                                </Tr>
                            </Tbody>
                            <Tbody>
                                <Tr>
                                    <Td>220.000.000,00</Td>
                                    <Td >Linha 6</Td>
                                    <Td isNumeric>-</Td>
                                    <Td isNumeric>0,00</Td>
                                    <Td isNumeric>58.076,77</Td>
                                    <Td isNumeric>58.076,77</Td>
                                </Tr>
                            </Tbody>

                        </Table>
                    </TableContainer>
                </VStack>

            </PageModel>
        </>
    )
}

export default Main