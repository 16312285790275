import React from 'react';
import {
  Box,
  Text,
  Card as ChakraCard,
  CardBody,
  CardFooter,
  Flex,
  ChakraProvider,
  Button,
  VStack,
  Heading,
  Container,
} from '@chakra-ui/react';
import Footer from '../../../Theme/layout/Footer';
import Header from '../../../Theme/layout/Header';
import { useNavigate } from 'react-router-dom';
import PageModel from '../../../Pages/PageModel';
import Pdf1 from '../../../../assets/pdfs/cct-2022-2023-registrada-no-MTE-1.pdf';
import Pdf2 from '../../../../assets/pdfs/Primeiro-Aditivo-CCT-22.23-mediador-11.pdf';
import Pdf3 from '../../../../assets/pdfs/Piso-CCT-Aditivo-4.pdf';
import Pdf4 from '../../../../assets/pdfs/OficioCircular01.2020.pdf';
import Pdf5 from '../../../../assets/pdfs/convencao_coletiva_de_trabalho20182019.pdf';
import Pdf6 from '../../../../assets/pdfs/20232024.pdf';
import Pdf7 from '../../../../assets/pdfs/Relatório de Arquivamento. PA (ARB_MED) 108778.2023.pdf';
import Pdf8 from '../../../../assets/pdfs/Convenção Coletiva de Trabalho  2023 - 2024 - Sinconpe Sintepav.pdf';
import Pdf9 from '../../../../assets/pdfs/Convenção Coletiva de Trabalho  2024 - 2025 - Sinconpe Sintepav.pdf';

const Card = ({ content, footer, bgImage, navegacao }) => {
  return (
    <Button
      backgroundColor={'blue.500'}
      color={'white'}
      fontWeight={'bold'}
      fontSize={'1em'}
      alignSelf="flex-end"
      borderRadius={'0.8em'}
      mt="1em"
      _hover={{ textDecoration: 'none' }}
      onClick={() => {
        window.open(navegacao);
      }}
    >
      {content}
    </Button>
  );
};

const Main = () => {
  const navigate = useNavigate();
  return (
    <ChakraProvider>
      <PageModel>
        <Container maxW={'8xl'} mt="2em">
          <Heading mb="1em">Convenção Coletiva de Trabalho</Heading>
          <Box mx={3}>
            <Card
              content="Convenção Coletiva de Trabalho  2024 - 2025 - Sinconpe Sintepav"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf9}
            />
          </Box>
          <Box mx={3}>
            <Card
              content="Convenção Coletiva de Trabalho  2023 - 2024 - Sinconpe Sintepav"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf8}
            />
          </Box>

          <Box mx={3}>
            <Card
              content="Relatório de Arquivamento. PA (ARB_MED) 108778.2023"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf7}
            />
          </Box>
          <Box mx={3}>
            <Card
              content="Oficio SINCONPE-CE - CCT 2023-2024 - Associados - Perda do Objeto"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf6}
            />
          </Box>
          <Box mx={3}>
            <Card
              content="Segundo Aditivo CCT - 2022/2023 - Piso Salarial"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf3}
            />
          </Box>
          <Box mx={3}>
            <Card
              content="Primeiro Aditivo CCT - 2022/2023 - Cesta Básica"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf2}
            />
          </Box>

          <Box mx={3}>
            <Card
              content="Convenção Coletiva de Trabalho 2022/2023"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf1}
            />
          </Box>

          <Box mx={3}>
            <Card
              content="Convenção Coletiva SINCONPE/CE 2020-2021"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf4}
            />
          </Box>

          <Box mx={3}>
            <Card
              content="Convenção Coletiva SINCONPE/CE 2018-2019"
              footer="Clique aqui e saiba mais"
              bgSize="cover"
              navegacao={Pdf5}
            />
          </Box>
        </Container>
      </PageModel>
    </ChakraProvider>
  );
};

export default Main;
