import { HStack, Heading, chakra } from '@chakra-ui/react';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Logo1 from '../../../assets/logo/2014/11/cosampa.png';
import Logo2 from '../../../assets/logo/2014/11/marquise.png';
import Logo3 from '../../../assets/logo/2014/11/Construtora-Ferreira-Guedes.png';
import Logo4 from '../../../assets/logo/2014/11/logo-passarelli.png';
import Logo5 from '../../../assets/logo/2014/11/caldas-furlani.png';
import Logo6 from '../../../assets/logo/2014/11/cbc.png';
import Logo7 from '../../../assets/logo/2014/11/construtora-beta.png';
import Logo8 from '../../../assets/logo/2014/11/copa_interna_logo.png';
import Logo9 from '../../../assets/logo/2014/11/Maciel.png';
import Logo10 from '../../../assets/logo/2014/11/logo-ssb-Versao-14.png';
import Logo11 from '../../../assets/logo/2015/03/qg.png';
import Logo12 from '../../../assets/logo/2014/11/Insttale.png';
import Logo13 from '../../../assets/logo/2014/11/lomacon.png';
import Logo14 from '../../../assets/logo/2015/03/pf_construcoes.png';
import Logo15 from '../../../assets/logo/2015/03/eng_exata.png';
import Logo16 from '../../../assets/logo/2014/11/cortez-engenharia-logotipo.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const sliderImageUrl = [
  //First image url
  {
    url: Logo1,
  },
  {
    url: Logo2,
  },
  {
    url: Logo3,
  },
  {
    url: Logo4,
  },
  {
    url: Logo5,
  },
  {
    url: Logo6,
  },
  {
    url: Logo7,
  },

  {
    url: Logo8,
  },

  {
    url: Logo9,
  },
  {
    url: Logo10,
  },

  {
    url: Logo12,
  },
  {
    url: Logo13,
  },

  {
    url: Logo14,
  },
  {
    url: Logo15,
  },
  {
    url: Logo16,
  },
];
const Associates = () => {
  return (
    <>
      <Heading w="100%" textAlign="center" pb="5%">
        Associados
      </Heading>
      <chakra.div className="parent" h="40vh" p="20" bg="gray.200">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
          transitionDuration={1000}
          partialVisible={false}
          dotListClass="custom-dot-list-style"
        >
          {sliderImageUrl.map((imageUrl, index) => {
            return (
              <HStack className="slider" key={index} p="1.5em">
                <chakra.img
                  h="16vh"
                  src={imageUrl.url}
                  p="0em 1.5em"
                  alt="movie"
                />
              </HStack>
            );
          })}
        </Carousel>
      </chakra.div>
    </>
  );
};
export default Associates;
