import { Button, Center, Heading, Modal, ModalBody, useToast, chakra, ModalCloseButton, Stack, ModalContent, ModalOverlay, Input, useColorModeValue, Box } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../Theme/Inputs/TextField';
function ModalCadastroEmail(prop) {
    const disclosure = prop.disclosure;
    const toast = useToast();
    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
                <ModalOverlay />
                <ModalContent maxW="85%" h="16em" borderRadius="0.8em">
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Heading textAlign="center" color="#808080" mt="5" size="md">
                                Cadastre seu email para receber as últimas notícias do setor
                                da construção pesada!
                            </Heading>
                        </Center>
                        <Formik
                            initialValues={{ email: '' }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .required('Email obrigatório!')
                                    .trim()
                                    .matches(
                                        '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$',
                                        'Email inválido'
                                    ),
                            })}
                            onSubmit={(values, actions) => {
                                try {
                                    const vals = {
                                        to_message: values.email,
                                    };
                                    console.log(vals)
                                    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/email/sendFirtsEmail`, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(vals),
                                    }).catch(function (err) {
                                        console.log(err)
                                    })
                                        .then((response) => {
                                            console.log(response)
                                            if (!response || !response.ok || response.status >= 400) {
                                                toast({
                                                    title: 'Indisponível',
                                                    description: 'Não foi possível enviar um e-mail de contato no momento. Tente novamente',
                                                    status: 'warning',
                                                    duration: 4000,
                                                    isClosable: true,
                                                    position: 'top',
                                                    colorScheme: 'red',
                                                    containerStyle: {
                                                        paddingTop: '50px',
                                                    },
                                                })
                                                return;
                                            }
                                            toast({
                                                title: 'Sucesso!',
                                                description: 'E-mail de cadastro enviado. Obrigado por entrar em contato conosco.',
                                                status: 'sucess',
                                                duration: 4000,
                                                isClosable: true,
                                                position: 'top',
                                                colorScheme: 'green',
                                                containerStyle: {
                                                    paddingTop: '50px',
                                                },
                                            })

                                            return response.json();
                                        })
                                } catch (error) {
                                    console.log(error)
                                }

                            }}
                        >
                            <Form>
                                <Box mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
                                    <TextField name="email" type="email" color={useColorModeValue('gray.800', 'gray.200')}
                                        bg={useColorModeValue('gray.100', 'gray.600')}
                                        rounded={'full'}
                                        border={0}
                                        _focus={{
                                            bg: useColorModeValue('gray.200', 'gray.800'),
                                            outline: 'none',
                                        }} />
                                    <Button
                                        bg={'blue.400'}
                                        rounded={'full'}
                                        color={'white'}
                                        flex={'1 0 auto'}
                                        type='submit'
                                        borderRadius={'0.8em'}
                                        mt="1em"
                                        _hover={{ bg: 'blue.500' }}
                                        _focus={{ bg: 'blue.500' }}
                                    >
                                        Cadastrar
                                    </Button>
                                </Box>
                            </Form>
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalCadastroEmail