import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Image,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import LogoSinconpece from '../../../assets/logo/1.png';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AccountContext } from '../../AccountContext';
import TextField from '../../Theme/Inputs/TextField';
import ModalSenha from '../../Administration/Auth/Modal/ModalEsqueciSenha';
import Banner22 from '../../../assets/banner22.png';
export default function Login() {
  const navigate = useNavigate();
  const modalEmailDisclousure = useDisclosure();
  const { setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <ModalSenha
        disclosure={modalEmailDisclousure}
        display={{ md: 'none', lg: 'none' }}
      />
      <Flex flex={1}>
        <Image alt={'Login Image'} objectFit={'cover'} src={Banner22} />
      </Flex>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <VStack alignItems="center" w="100%">
            {' '}
            <Image w={{ lg: '40%' }} src={LogoSinconpece} />
          </VStack>
          <Formik
            initialValues={{ username: '', password: '' }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
              username: Yup.string()
                .required('Email obrigatório!')
                .trim()
                .matches(
                  '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$',
                  'Email inválido'
                ),
              password: Yup.string()
                .required('Senha Obrigatória!')
                .min(6, 'Senha inválida!'),
            })}
            onSubmit={(values, actions) => {
              const vals = {
                username: values.username,
                password: values.password,
              };
              actions.resetForm();
              fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(vals),
              })
                .catch((err) => {
                  //showToast();
                  return;
                })
                .then((res) => {
                  if (!res || !res.ok || res.status >= 400) {
                    //showToast();
                    return;
                  }
                  return res.json();
                })
                .then((data) => {
                  if (!data) return;
                  setUser((prevState) => ({
                    ...prevState,
                    ...data,
                  }));
                  if (data.status) {
                    setError(data.status);
                  } else if (data.loggedIn) {
                    navigate('/dashboard');
                  }
                });
            }}
          >
            <Form>
              <TextField label="Email" name="username" type="email" />

              <TextField label="password" name="password" type="password" />

              <Stack spacing={6}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                >
                  <Checkbox>Permanecer logado</Checkbox>
                  <Text
                    color={'blue.500'}
                    cursor={'pointer'}
                    onClick={() => {
                      modalEmailDisclousure.onOpen();
                    }}
                  >
                    Esqueci minha senha
                  </Text>
                </Stack>
                <Button colorScheme={'blue'} variant={'solid'} type="submit">
                  Entrar
                </Button>
              </Stack>
            </Form>
          </Formik>
        </Stack>
      </Flex>
    </Stack>
  );
}
