import { Box, Container, Flex, Heading, Stack, Text } from '@chakra-ui/react';

import ServiceCard from '../../Theme/Cards/ServiceCard';
import teste from '../Sections/Fiscal/Main';

export default function Services() {
  const imageArray = [
    {
      url: 'https://img.freepik.com/free-photo/cropped-image-businessman-sitting-by-table-cafe-analyzing-indicators-laptop-computer_171337-5598.jpg?size=626&ext=jpg&ga=GA1.1.2100341080.1696331285&semt=sph',
      title: 'Índices do Setor da Construção Pesada',
      route: '/indice',
      color: `rgba(85, 108, 146, 0.9)`,
    },
    {
      url: 'https://img.freepik.com/free-photo/cropped-view-businesswomen-reading-document_74855-4169.jpg?w=1800&t=st=1696331319~exp=1696331919~hmac=db31cc2178427c8f0bb78db766de4bf9e4d337d5bdfb45072d9beaede2a84805',
      title: 'Convenção coletiva',
      route: '/convencao',
      color: `rgba(85, 108, 146, 0.9)`,
    },
    {
      url: 'https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-30568.jpg?w=1800&t=st=1696331438~exp=1696332038~hmac=42e329f6c1dc44eb9d48a7e4a909a11a61b332c6736033cc0dae310a93eaddf0',
      title: 'Convênios',
      route: '/convenio',
      color: `rgba(85, 108, 146, 0.9)`,
    },
    {
      url: 'https://img.freepik.com/free-photo/entrepreneur-working-with-bills_1098-20001.jpg?size=626&ext=jpg&ga=GA1.1.2100341080.1696331285&semt=ais',
      title: 'Contribuição Sindical',
      route: '/fiscal',
      color: `rgba(85, 108, 146, 0.9)`,
    },
  ];
  return (
    <Box p={4} mb="5%">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Serviços
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          O Sindicato da Indústria da Construção Pesada oferece um pacote vital
          de serviços. Desde treinamentos para aprimorar habilidades até
          advocacia por regulamentações seguras, o sindicato constrói pontes
          para o sucesso.
        </Text>
      </Stack>

      <Container maxW={'8xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {imageArray?.map((image, index) => (
            <ServiceCard
              src={image.url}
              route={image.route}
              title={image.title}
              index={index}
              color={image.color}
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
}
