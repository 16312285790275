import { Heading, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Center, Button, Stack, Flex, ButtonGroup, Input, HStack, FormLabel, FormControl, useColorModeValue, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../Theme/Inputs/TextField';

function CadastroServicos(prop) {
    const modal = prop.disclosure;
    let refetch = prop.refetch
    const [servicos, setServicos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    function sendForm(dados, actions) {
        modal.onClose()
        let formData = new FormData()
        const imageData = document.getElementById('fotografia')
        formData.append('image', imageData.files[0])
        formData.append('nome', dados.nome)
        formData.append('descricao', dados.descricao)
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/setServicos/`, {
            method: 'POST',
            body: formData,
        }).catch(function (err) {
            console.log(err)
        }).then(async (response) => {
            return response.json()
        }).then(async (value) => {
            prop.handleResult(true)
            refetch = true
            formData.append('servico_id', value)
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/image/postImageServicos/`, {
                method: 'POST',
                body: formData,
            }).catch(function (err) {
                console.log(err)
            }).then(async (response) => {
                return response.json()
            }).then(async (value) => {
                console.log(value)
            })
        })
    }

    return (
        <>
            <Modal isOpen={modal.isOpen} isCentered onClose={modal.onClose}>
                <ModalOverlay />
                <Formik
                    initialValues={{ nome: '', descricao: '' }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={false}
                    onSubmit={(values, actions) => {
                        sendForm(values, actions)
                    }}

                >
                    <ModalContent maxW={{ md: "30%", lg: "30%", sm: "90%" }} h="25em" borderRadius="0.5em">
                        <ModalCloseButton />
                        <ModalBody>
                            <Center>
                                <Heading textAlign="center" color="#808080" mt="5" size="md">
                                    Digite os dados para cadastrar um novo serviço
                                </Heading>
                            </Center>

                            <Form >
                                <Box mt={4}>
                                    <Box>
                                        <FormLabel>Nome</FormLabel>
                                        <TextField type="text" name="nome" placeholder="Digite seu nome" />
                                    </Box>

                                    <Box mt={4}>
                                        <FormLabel>Descrição</FormLabel>
                                        <TextField type="text" name="descricao" placeholder="Digite seu email" />
                                    </Box>

                                    <Box mt={4}>
                                        <FormLabel>Foto</FormLabel>
                                        <Input name="fotografia" id="fotografia" type="file" />
                                    </Box>

                                    <ButtonGroup>
                                        <Button type="submit" mt={4} colorScheme="green">
                                            Cadastrar
                                        </Button>
                                        <Button mt={4} colorScheme="red" onClick={() => { modal.onClose() }}>
                                            Fechar
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </Form>


                        </ModalBody>
                    </ModalContent>
                </Formik>
            </Modal>
        </>
    )
}

export default CadastroServicos