import React, { useEffect, useState } from 'react';
import CadastroServicos from '../Modal/EditarServicos';
import { Box, Button, ButtonGroup, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

function ServicesList(prop) {
    const modal = useDisclosure();
    const [servicos, setServicos] = useState([]);
    const [id_descricao, setIdDescricao] = useState();
    const [nome_descricao, setNomeDescricao] = useState();
    const [descricao, setDescricao] = useState();
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/getServicos`;
    useEffect(() => {
        const fetchPosts = async () => {
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
            const postsData = await response.json();
            setServicos(postsData);
        };
        fetchPosts();
    }, []);

    useEffect(() => {
        if (prop.fetchAtualizado === true) {
            prop.handleResult().then((postsData) => {
                setServicos(postsData);
            });
        }
    }, [prop.fetchAtualizado]);


    function excluirServico(id) {
        const vals = {
            id: id,
        }
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/servicos/deleteServicos`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
        }).catch(function (err) {
            console.log(err)
        }).then(async (response) => {
            const pesquisa = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
            const postsData = await pesquisa.json();
            setServicos(postsData);
            return response
        })
    }

    const userServicos = servicos.map((servico, index) => {
        return (
            <>
                <Tr key={index}>
                    <Td>

                        {servico.name}
                    </Td>
                    <Td>
                        {servico.description}
                    </Td>
                    <Td>
                        <ButtonGroup>
                            <Button colorScheme='green' onClick={() => {
                                setIdDescricao(servico.establishment_service_id)
                                setDescricao(servico.description)
                                setNomeDescricao(servico.name)
                                modal.onOpen()
                            }}>Editar</Button>
                            <Button colorScheme='red' onClick={() => { excluirServico(servico.establishment_service_id) }}>Excluir</Button>
                        </ButtonGroup>
                    </Td>
                </Tr>
            </>
        );
    })

    return (
        <>
            <CadastroServicos disclosure={modal} id_descricao={id_descricao ? id_descricao : ''} nome={nome_descricao} descricao={descricao} />
            <TableContainer minWidth="100%">
                <Table>
                    <Thead>
                        <Tr>
                            <Th fontSize="lg">Nome</Th>
                            <Th fontSize="lg">Descrição</Th>
                            <Th fontSize="lg">Ações</Th>
                        </Tr>
                    </Thead>
                    <Tbody>{servicos && userServicos}</Tbody>
                </Table>
            </TableContainer>

        </>
    );

}

export default ServicesList