import React, { useState } from 'react'
import { Button, Center, Heading, Modal, ModalBody, useToast, chakra, ModalCloseButton, Stack, ModalContent, ModalOverlay, Input, useColorModeValue, Box, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import TextField from '../../../Theme/Inputs/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function ModalValidacao(prop) {
    const disclosure = prop.disclosure;
    const email = prop.email
    const toast = useToast();
    const [validacao, SetValidacao] = useState(false);
    const [client_id, SetClient_id] = useState('');

    function callToast(color, title, description, status) {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: color,
            containerStyle: {
                paddingTop: '50px',
            },
        })
        return toast
    }

    function validationCode(code, actions) {
        const vals = {
            code: code,
            email: email,
            client_id: client_id ? client_id : 0
        };
        if (validacao === false) {
            try {
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/getCodeByClient/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(vals),
                }).catch(function (err) {
                    console.log(err)
                }).then(async (response) => {
                    return response.json()
                }).then(async (resposta) => {
                    console.log(resposta)
                    if (resposta.sucess === false) {
                        SetValidacao(false)
                        callToast('red', 'Indisponível', 'O código informado está incorreto. Tente novamente', 'warning')
                    } else {

                        SetClient_id(resposta.data.id_client)
                        SetValidacao(true)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        } else {
            actions.resetForm();
            updatePassword(vals, actions)
        }
    }

    function updatePassword(data, actions) {
        try {
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/updatePasswordById/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).catch(function (err) {
                console.log(err)
            }).then(async (response) => {
                return response.json()
            }).then(async (resposta) => {
                if (resposta.sucess === false) {
                    callToast('red', 'Indisponível', 'Não foi possível atualizar sua senha. Tente novamente', 'warning')
                } else {

                    callToast('green', 'Sucesso!', 'Sua senha foi alterada com sucesso', 'success')
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <ModalBody>
                <Center>
                    <Heading textAlign="center" color="#808080" mt="5" size="md">
                        {validacao === true ? 'Defina sua nova senha' : 'Infome o código enviado para o seu email'}
                    </Heading>
                </Center>

                <Formik
                    initialValues={{ email: '' }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Yup.object({
                        code: Yup.string().matches(/^\d+$/, 'O código deve conter apenas números').required(),
                    })}
                    onSubmit={(values, actions) => {
                        validationCode(values.code, actions)
                        actions.resetForm();
                    }}
                >
                    <Form>
                        <Box mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
                            <TextField
                                name="code"
                                type={validacao === true ? 'text' : 'number'}
                                color={useColorModeValue('gray.800', 'gray.200')}
                                bg={useColorModeValue('gray.100', 'gray.600')}
                                rounded={'full'}
                                border={0}
                                _focus={{ bg: useColorModeValue('gray.200', 'gray.800'), outline: 'none', }}
                            />
                            <ButtonGroup >
                                <Button
                                    bg={'green.400'}
                                    rounded={'full'}
                                    color={'white'}
                                    flex={'1 0 auto'}
                                    borderRadius={'0.8em'}
                                    mt="2em"
                                    _hover={{ bg: 'blue.500' }}
                                    _focus={{ bg: 'blue.500' }}
                                    type='submit'

                                >
                                    {validacao === true ? 'Definir nova senha' : 'Validar Código'}
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Form>
                </Formik>
            </ModalBody>
        </>
    )
}

export default ModalValidacao