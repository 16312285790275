import {
  Box,
  Image,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  HStack,
  VStack,
  Button,
} from '@chakra-ui/react';

export default function Schedule() {
  return (
    <>
      <Heading as="h1" w="100%" textAlign="center">
        Agenda
      </Heading>
      {/* flexWrap="wrap" */}
      <HStack py={6} spacing="5%" p="12" gridGap={6}>
        <Box
          maxW={'445px'}
          w={'full'}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Stack>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}
            >
              Edital de Convocação
            </Text>
            <Heading
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}
            >
              Assembleia Geral Extraordinária
            </Heading>
            <Text color={'gray.500'}>05/02/2024 ás 08:30</Text>
            <Text color={'gray.500'}>
              Local: Sede do Sinconpe – Rua Tomás Acioli, 840 – 3º andar, sala
              304 – Aldeota
            </Text>
          </Stack>
        </Box>
        <Box
          maxW={'445px'}
          w={'full'}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Stack>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}
            >
              Edital de convocação
            </Text>
            <Heading
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}
            >
              Assembleia Geral Extraordinária
            </Heading>
            <Text color={'gray.500'} textAlign="justify">
              22/09/2023 (sexta-feira) , às 10:30h, em primeira convocação e,
              não havendo quórum, às 11:00h, em segunda convocação, com qualquer
              número de presentes.
            </Text>
            <Text color={'gray.500'}>
              Local: Sede do Sinconpe – Rua Tomás Acioli, 840 – 3º andar, sala
              304 – Aldeota
            </Text>
          </Stack>
        </Box>
        <Box
          maxW={'445px'}
          w={'full'}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Stack>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}
            >
              Reunião estendida
            </Text>
            <Heading
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}
            >
              Projeto Planta Fortescue de Hidrogênio Verde
            </Heading>
            <Text color={'gray.500'}>18/07/2023 (terça-feira) as 15:00</Text>
            <Text color={'gray.500'}>Local: Auditório Waldyr Diogo</Text>
          </Stack>
        </Box>
      </HStack>
      <VStack w="100%" spacing={'10'}>
        <Button mb="5%" size="lg" colorScheme="twitter">
          Veja Mais
        </Button>
      </VStack>
    </>
  );
}
